module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mobile Reality","short_name":"Mobile Reality","start_url":"/","background_color":"#F53103","theme_color":"#F53103","display":"minimal-ui","icon":"static/favicons/favicon.svg","description":"Click, to create a shortcut on the main screen.","lang":"en","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4258b2a02da160d7c1e935c3f1539065"},
    },{
      plugin: require('../node_modules/@mobile-reality/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"facebookPixel":{"pixelId":"611206113536213","cookieName":"gatsby-gdpr-facebook-pixel"},"hotjar":{"hjid":"1250240","hjsv":"6","cookieName":"gatsby-gdpr-hotjar"},"linkedin":{"trackingId":"450348","cookieName":"gatsby-gdpr-linkedin"},"googleTagManager":{},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"fileName":false,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://themobilereality.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-axe-core-react/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
